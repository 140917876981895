import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Layout from '../../../components/layout'

import {
	Container,
	Section,
} from '../../../components/layouts'

import {
	Subtitle,
	Paragraph,
	BlogAuthor,
	Link,
} from '../../../components/ui'

import powerPageHeading from '../../../assets/images/powerpage_heading.png'

import theme from '../../../theme/theme'

const {
	intro,
} = theme.sections

class IndexPage extends React.Component {

	componentDidMount() {
		window.scrollTo(0, -1500)
	}

	render () {
		return (
			<Layout>
				<Section id="intro" bg={intro.bg} paddingTop={'100px'}>
					<Container  >
						<img alt="Turning Millennials into Donors" src={powerPageHeading} width="100%"/>

						<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }}>Actionable tips and methods for nonprofits looking to reach and engage millennials as active donors </Subtitle><br/>

						<Paragraph>
          Millennials--the generation rapidly changing the way we eat, work, exercise, shop, and now, the way we give. Characterized as the generation born between 1980 and 2000, millennials today range from the ages 18 to 38. With millennials now making up the largest portion of the population, it is imperative that nonprofits begin engaging and securing them as donors. But despite making up more than a quarter of the population, millennials account for only 11% of charitable giving.
							<br/><br/>This may come as a surprise, but millennials have really good intentions. Often referred to as the purpose-driven generation, millennials care about doing social good and yearn to make an impact on the world around them. But there’s a problem--according Blackbaud’s Next Generation of American Giving study, only 25% of millennials believe that monetary giving is the best way to make a difference with charitable organizations.
							<br/><br/>So how can you, as a nonprofit, turn well-meaning, “I’m-going-to-save-the-world” millennials into tangible donors? And even better--how can you turn them into recurring donors?
          In this guide, I cover seven approaches that will help your nonprofit both involve and capture the millennial generation.

							<ul>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter1/'}>Chapter 1: 3 ways to get Millennials directly engaged with your organization</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter2/'}>Chapter 2: Generate Social Currency (aka Millennial Candy)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter3/'}>Chapter 3: 4 Methods of Transparency that Millennials Love</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter4/'}>Chapter 4: How To Make Your Social Media Attractive and Effective</Link></li>
								<li>Chapter 5: Turn the Tables By Getting Millennials To Fundraise For You</li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter6/'}>Chapter 6: How to get Millennials to Pull the Trigger and Donate (in 2 minutes)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter7/'}>Chapter 7: Turning One-Time Donors into Lifetime Donors</Link></li>
							</ul>

							<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }} id="chapter5">Chapter 5: Turn the Tables By Getting Millennials To Fundraise For You</Subtitle>
						Who is better at engaging millennials than millennials themselves? Millennials like to think of themselves as “world changers” or “social catalysts”. They not only want to support good work being done, they want to be involved in getting that good work done (it’s all part of their search for purpose). So what better way to get them involved by motivating them to fundraise for the organization they love?
							<br/><br/>
						How do you do this? <strong> Peer-to-peer fundraising. </strong>This is a special form of crowdfunding--that buzzword you’ve probably heard thrown around the nonprofit space lately. Crowdfunding is where millennials shine when it comes to giving--making up about 33% of giving to crowdfunding campaigns versus only 11% of traditional charitable giving.
						The <Link style={{ display: 'inline' }} href="https://www.dressember.org/"> Dressember Foundation </Link> is a perfect example of how a simple peer-to-peer campaign can raise millions of dollars for an organization. What started as a campaign consisting of one woman wearing a dress every single day in December to raise awareness for slavery and sex trafficking has transformed into a foundation that has raised over $5 million since 2013.
							<br/><br/>Pulling off a successful crowdfunding campaign is actually easier than you’d think. I’ve put it together in a simple formula:
							<br/> <Subtitle style={{ textAlign: 'center', marginTop: '1em' }}>[ Campaign + Advocates + Action + Crowdfunding Platform= SUCCESS!] </Subtitle><br/>
							<ul><li><strong>Campaign:</strong> What are you raising money for? You can make this broad as your organization’s general mission or as specific as a certain project you want to complete. Make sure it’s a cause that will tug at people’s hearts a little bit (aka don’t fundraise for your marketing budget). Be sure to set a clear and attainable goal for how much you want to raise in your campaign. </li>
								<li><strong>Advocates:</strong>Create a call to action for people (especially your lovely millennials) to sign up to be an advocate for your campaign. This is the tricky part, in order to have a successful peer-to-peer campaign, you need to make sure you capture those millennials who really love your cause. Reach out to past interns or intern applicants, volunteers, college chapters, and make sure to use your social media. <br/> <br/>
						**Millennials love being advocates for a campaign because it is a goldmine of <Link onClick={this.linking} id="pageLink" style={{ display: 'inline' }} href="#chapter2">social currency</Link>

								</li>
								<li><strong>Action:</strong> Give your advocates an action to perform. For Dressember, this action was wearing a dress for every day in December. Other nonprofits use peer-to-peer fundraising for events such as running a marathon. Give your action some sort of challenge (so that people will be willing to support your advocates in their venture) but also simple enough that you don’t eliminate potential advocates.</li>
								<li><strong>Crowdfunding Platform:</strong> Find a crowdfunding platform to launch your campaign on. <Link style={{ display: 'inline' }} href="https://www.classy.org">Classy</Link>, <Link style={{ display: 'inline' }} href="https://www.crowdrise.com">CrowdRise</Link>, and <Link style={{ display: 'inline' }} href="https://rally.org">Rally</Link>, are just a few of the many platforms designed for nonprofits to set up their peer-to-peer campaigns.  </li>
							</ul>

							<strong>**A simple trick to make your Millennial donors feel valued</strong>: <i>Ask for their opinions on small things.</i> Maybe your organization builds orphanages. Reach out to your donors with something like this: “Hey there! Construction on the orphanage in Port-au-Prince, Haiti, is almost done! Should we paint the walls blue or yellow?” It really doesn’t matter to you what color the walls are, but a donor will feel exponentially more involved and bought into your organization.

							<hr style={{ margin: '1em 0' }}/>

							<Subtitle fontSize={'30px'}>
							Looking for the full e-book?
							</Subtitle>
							<Paragraph
								fontSize={'21px'}
								marginRight={"2em"}
								fontWeight={'200'}>Fill out this form to receive our free guide <span style={{ fontStyle: 'italic' }}>Millennials To Donors</span></Paragraph>
							<HubspotForm
								id="reactHubspotForm"
								portalId="4829666"
								formId="1c650ab1-5fd3-4250-88c3-269180d71e88"
								loading={<div>{'Loading...'}</div>}
							/>

							<hr style={{ margin: '1em 0' }}/>
							<br/>
							<br/>
              Now I’ll turn it over to you. What do you think of this guide? Is there anything I missed or you want to see covered? Please send me your thoughts at <Link style={{ display: 'inline' }} href="mailto:laura@flourishchange.com">laura@flourishchange.com</Link>.

						</Paragraph>
						<BlogAuthor author="laura"/>
					</Container>

				</Section>
			</Layout>
		)
	}
}

export default IndexPage
